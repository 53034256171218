import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";


import Layout from "../components/layout";
import SEO from "../components/seo";
import HeroPage from '../components/services/hero';
import Faqs from '../components/faq/faqs';

const IndexPage = ({
  pageContext: {
    faqPage
  }
}) => {
  const homePageSeoTitle = "C&R Asphalt Animals |  Lexington's Top Rated Paving Company | Serving Lexington, Louisville, Georgetown, Versailles, Frankfort, Winchester";
  return (
    <Layout>
      <SEO title={homePageSeoTitle} seo={faqPage.seo?.[0]} />
      <HeroPage
        title={faqPage.title}
        subtitle={faqPage.subtitle}
        cta={'Start a project'}
        image={faqPage.mainImage} />
      <Faqs faqs={faqPage.questions} />
    </Layout>
  );
};

const mapStateToProps = state => {
  return {  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
    }, dispatch),
  }
};

const Index = connect(
  mapStateToProps,
  mapDispatchToProps
)(IndexPage);

export default Index;
