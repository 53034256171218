import React from 'react';
import {
  Typography,
  Divider,
  Accordion, AccordionSummary, AccordionDetails
} from '@material-ui/core';
import {
  ExpandMore
} from '@material-ui/icons';
import {
  makeStyles
} from '@material-ui/styles';
import PortableText from '../content/portableText';
import '../about/teamMembers/portableTextStyles.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2),
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      padding: 0
    }
  },
  card: {
    background: theme.palette.background.default,
  },
  accordion: {
    maxWidth: 1100,
    background: theme.palette.background.default,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100vw'
    }
  },
  divider: {
    marginBottom: theme.spacing(2)
  },
}))

export default ({faqs}) => {
  const classes = useStyles();
  return <div className={classes.root}>
    {faqs.map((question, idx) => {
      return (
        <Accordion square  className={classes.accordion} key={idx}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant='h6'>{question.question}</Typography>
          </AccordionSummary>
          <Divider className={classes.divider} />
          <AccordionDetails>
            <PortableText blocks={question._rawAnswer} />
          </AccordionDetails>
        </Accordion>
      )
    })}
  </div>;
}
